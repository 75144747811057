import {
  ColDef,
  ColGroupDef,
  GetContextMenuItems,
  GridApi,
  IRowNode,
  MenuItemDef,
  RowClassParams,
  RowSelectionOptions
} from '@ag-grid-community/core'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useDebounce } from 'react-use'
import { useOpenFin } from '../../app/openFinContext'
import {
  BEST_BID_PRICE,
  BEST_BID_SPREAD,
  BEST_OFFER_PRICE,
  BEST_OFFER_SPREAD,
  CHECKBOX,
  ISSUER,
  mirrorColumnMapping
} from '../../containers/BondList/columnDefs'
import { useManageOneOrderType } from '../../helpers/hooks/useManageMyOrders'
import { updateColumnsOrder } from '../../store/grid/actions'
import { getColumnsOrder } from '../../store/grid/selectors'
import { isMyOrdersOpen } from '../../store/order/selectors'
import { Security } from '../../store/securities/reducer'
import { getWatchlistId } from '../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getCurrentTheme } from '../../store/userPreferences/selectors'
import {
  appendIssuerToWatchlist,
  appendSecurityToWatchlist
} from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'
import { applyColumnsOrder } from './helpers'

type MinimalGridApi = {
  api: GridApi
}
type UseGridControlsParams = {
  gridApi?: MinimalGridApi | null
  gridIndex: number
  canEditWatchlist: boolean
  setSelectedDetailSecurityId: (securityId: number) => void
  currentPage?: number
  onClickGridRow: (securityId: number) => void
  onDoubleClickGridRow: (securityId: number) => void
}

const getSelectedSecurityId = ({ api }: { api: GridApi }) => {
  const rowIndex = api?.getFocusedCell()?.rowIndex
  if (rowIndex !== undefined) {
    return api?.getDisplayedRowAtIndex(rowIndex)?.data?.id
  }
}

export const useGridControls = ({
  gridApi,
  gridIndex,
  canEditWatchlist,
  setSelectedDetailSecurityId,
  onClickGridRow,
  onDoubleClickGridRow
}: UseGridControlsParams) => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)

  const myOrdersOpen = useAppSelector(isMyOrdersOpen)(gridIndex)

  const [colsAsString, setColsAsString] = useState('')

  const handleColumnChange = useCallback(() => {
    const displayed = gridApi?.api.getAllDisplayedColumns().map((col) => {
      return col.getColId()
    })
    if (displayed) {
      setColsAsString(displayed.join(','))
    }
  }, [gridApi])

  useDebounce(
    () => {
      if (colsAsString) {
        dispatch(updateColumnsOrder(gridIndex, colsAsString.split(',')))
      }
    },
    1000,
    [colsAsString]
  )

  const { fin } = useOpenFin()

  // ------------ Watch Lists ------------ //
  const watchlists = useAppSelector(getWatchList)
  const watchlistIdSelected = useAppSelector(getWatchlistId)(gridIndex)
  //
  // Action Dispatching
  const addBondToWatchlist = (data: any, id: number | undefined) => {
    if (id) {
      dispatch(appendSecurityToWatchlist(id, [data.id]))
    }
  }

  const addIssuerToWatchlist = (
    data: Security,
    id: number | undefined,
    append: boolean
  ) => {
    if (id) {
      dispatch(appendIssuerToWatchlist(id, data.issuerSymbol, append))
    }
  }
  const getBondWatchlistContextMenu = useCallback(
    (data: Security): MenuItemDef | string => {
      return watchlists?.length
        ? {
            name: 'Add bond to watchlist',
            subMenu: watchlists?.map((wl) => {
              return {
                name: wl.name,
                action() {
                  addBondToWatchlist(data, wl.id)
                }
              }
            })
          }
        : ''
    },
    [watchlists]
  )

  const getIssuerWatchlistContextMenu = useCallback(
    (data: Security) => {
      return watchlists?.length
        ? {
            name: 'Add issuer to watchlist',
            subMenu: watchlists?.map((wl) => {
              return {
                name: wl.name,
                action() {
                  addIssuerToWatchlist(data, wl.id, true)
                }
              }
            })
          }
        : ''
    },
    [watchlists]
  )

  const getRemoveIssuerContextMenu = useCallback(
    (data: Security) => {
      return canEditWatchlist
        ? {
            name: 'Remove issuer from watchlist',
            action() {
              addIssuerToWatchlist(data, watchlistIdSelected, false)
            }
          }
        : ''
    },
    [watchlistIdSelected, canEditWatchlist]
  )

  // ag grid catches contextMenu event, so we don't get this in new DOM
  const getCopyCusipItem = useCallback((security: Security) => {
    return {
      name: 'Copy CUSIP',
      action() {
        const identifier = security.cusip || security.isin || ''
        if (fin) {
          fin.Clipboard.write({
            data: {
              text: identifier
            }
          })
        } else {
          navigator.clipboard.writeText(identifier)
        }
      }
    }
  }, [])

  const getContextMenuItems: GetContextMenuItems<Security> = useCallback(
    (params) => {
      const data = params.node?.data
      if (!data) return []
      return [
        {
          name: 'View Security Details',
          action() {
            if (data?.id) {
              setSelectedDetailSecurityId(data.id)
            }
          }
        },
        getCopyCusipItem(data),
        getBondWatchlistContextMenu(data),
        getIssuerWatchlistContextMenu(data),
        getRemoveIssuerContextMenu(data)
      ].filter((item) => !!item)
    },
    [
      getBondWatchlistContextMenu,
      getIssuerWatchlistContextMenu,
      getRemoveIssuerContextMenu
    ]
  )

  // order managers
  const buyOrderManager = useManageOneOrderType('buy')
  const sellOrderManager = useManageOneOrderType('sell')

  const gridContext = useMemo(
    () => ({
      buyOrderManager,
      sellOrderManager,
      gridIndex
    }),
    [buyOrderManager, sellOrderManager, gridIndex]
  )

  // ------------ Columns ------------ //
  const columnsOrderSelector = useAppSelector(getColumnsOrder)
  const isAdmin = useAppSelector(getIsAdmin)
  const columnsOrder = useMemo(() => {
    return columnsOrderSelector(gridIndex)
  }, [columnsOrderSelector, gridIndex])

  const [columnDefs, setColumnDefs] = useState<
    Array<ColDef | ColGroupDef> | undefined
  >(undefined)

  // Set Initial Columns Def
  useLayoutEffect(() => {
    setColumnDefs(applyColumnsOrder(gridIndex, isAdmin, columnsOrder))
  }, [columnsOrder?.join(',')])

  // Handle My Orders group toggling
  useEffect(() => {
    if (gridApi) {
      const state = gridApi.api.getColumnState()

      state.map((col) => {
        if (col.pinned === 'right') {
          col.hide = !myOrdersOpen
        }
        return col
      })
      gridApi.api.applyColumnState({ state })
      gridApi.api.setColumnGroupOpened('myOrders', myOrdersOpen)
      if (myOrdersOpen) {
        const openNodes = gridApi.api
          .getRenderedNodes()
          .filter((node) => node.expanded)
        if (openNodes.length) {
          gridApi.api.redrawRows({
            rowNodes: openNodes
          })
        }
      }
    }
  }, [gridApi?.api, myOrdersOpen])

  // Toggle My Orders mirror columns
  const visibilities: Record<string, boolean | undefined> = {
    [BEST_BID_SPREAD]: gridApi?.api.getColumn(BEST_BID_SPREAD)?.isVisible(),
    [BEST_BID_PRICE]: gridApi?.api.getColumn(BEST_BID_PRICE)?.isVisible(),
    [BEST_OFFER_SPREAD]: gridApi?.api.getColumn(BEST_OFFER_SPREAD)?.isVisible(),
    [BEST_OFFER_PRICE]: gridApi?.api.getColumn(BEST_OFFER_PRICE)?.isVisible()
  }

  useLayoutEffect(() => {
    if (!gridApi) return
    if (myOrdersOpen) {
      const state = gridApi.api.getColumnState()
      state.map((col) => {
        if (!mirrorColumnMapping[col.colId]) return col
        // note this mutates the column, but replacing it screws up ag grid
        col.hide = true
        return col
      })
      gridApi.api.applyColumnState({ state })
    }
  }, [
    myOrdersOpen,
    gridApi,
    visibilities[BEST_BID_SPREAD],
    visibilities[BEST_BID_PRICE],
    visibilities[BEST_OFFER_SPREAD],
    visibilities[BEST_OFFER_PRICE]
  ])

  // Handle watchlist editing
  useEffect(() => {
    if (gridApi) {
      gridApi.api.setColumnsVisible([CHECKBOX], canEditWatchlist)
      gridApi.api.setColumnWidths([
        { key: ISSUER, newWidth: canEditWatchlist ? 70 : 90 }
      ])
    }
  }, [gridApi?.api, canEditWatchlist])

  // ------------ interacting with grid rows ------------ //
  const handleGridRowClick = useCallback(
    (api: MinimalGridApi) => {
      onClickGridRow(getSelectedSecurityId(api))
    },
    [onClickGridRow]
  )
  const handleGridRowDoubleClick = useCallback(
    (api: MinimalGridApi) => {
      onDoubleClickGridRow(getSelectedSecurityId(api))
    },
    [onDoubleClickGridRow]
  )

  // --------------- style grid rows --------------- //
  const getRowClass = useMemo(
    () =>
      ({ node }: RowClassParams<Security>) => {
        if (node.data?.isBenchmark) {
          return 'benchmarkRow'
        }
      },
    []
  )

  // ------------------ Master/detail ------------------ //
  /*
      For some reason, the grid doesn't redraw the renderer
      after closing and reopening my orders. This forces that
      to happen.
   */
  useEffect(() => {
    const api = gridApi?.api
    if (myOrdersOpen && api) {
      const rowNodes: Array<IRowNode<Security>> = []
      api.forEachNode((node) => {
        if (node.expanded) rowNodes.push(node)
      })
      api.redrawRows({ rowNodes })
    }
  }, [myOrdersOpen])

  // ------------------ row selection model ------------------ //

  const rowSelection = useMemo<RowSelectionOptions>(() => {
    return {
      checkboxes: false,
      enableClickSelection: 'enableSelection',
      mode: 'singleRow'
    }
  }, [])

  return {
    handleColumnChange,
    handleGridRowClick,
    handleGridRowDoubleClick,
    getContextMenuItems,
    getRowClass,
    columnDefs,
    gridContext,
    myOrdersOpen,
    rowSelection,
    theme
  }
}
