import {
  CellFocusedEvent,
  CellKeyDownEvent,
  ColDef,
  GetRowIdFunc,
  IRowNode,
  RowClassParams,
  RowClickedEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'

import { useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import { WatchList } from '../../../../store/watchList/types'
import { Resettable } from '../WatchlistManager'

type Props = {
  watchlists?: WatchList[]
  selectWatchlist: (watchlist: WatchList) => void
  selectedWatchlist?: WatchList
  deleteSelectedWatchlist: () => void
}

const getRowId: GetRowIdFunc<WatchList> = ({ data }) => `${data?.id ?? ''}`

const WatchlistGrid = (
  {
    watchlists,
    selectWatchlist,
    selectedWatchlist,
    deleteSelectedWatchlist
  }: Props,
  handle?: ForwardedRef<Resettable>
) => {
  const theme = useAppSelector(getCurrentTheme)

  const gridRef = useRef<AgGridReact<WatchList>>(null)
  const columns: Array<ColDef<WatchList>> = useMemo(() => {
    return [
      {
        field: 'name',
        filter: 'agTextColumnFilter'
      },
      {
        field: 'ownerUserName',
        headerName: 'Owner'
      },
      {
        field: 'customer',
        tooltipField: 'customer'
      },
      {
        field: 'permission',
        flex: 0.5,
        headerName: 'Permissions'
      }
    ]
  }, [])

  const onRowClicked = useCallback(
    ({ data: watchlist }: RowClickedEvent) => {
      selectWatchlist(watchlist)
    },
    [selectWatchlist]
  )

  const onCellFocused = useCallback(
    ({ api, rowIndex }: CellFocusedEvent<WatchList>) => {
      const node = api.getDisplayedRowAtIndex(rowIndex || -1)
      if (node?.data) selectWatchlist(node.data)
    },
    [selectWatchlist]
  )

  const onCellKeydown = useCallback(
    (cellEvent: CellKeyDownEvent) => {
      // @ts-ignore
      const key = cellEvent.event?.key
      if (key === 'Delete' || key === 'Backspace') {
        deleteSelectedWatchlist()
      }
    },
    [deleteSelectedWatchlist]
  )

  // adjust selection based on applied filters
  useEffect(() => {
    if (!selectedWatchlist) return
    let found = false
    let firstNode: IRowNode<WatchList>
    gridRef.current?.api?.forEachNodeAfterFilter(
      (node: IRowNode<WatchList>) => {
        if (node.data === selectedWatchlist) found = true
        if (!firstNode && node.data) firstNode = node
      }
    )
    // @ts-ignore
    if (firstNode && !found) {
      // @ts-ignore
      selectWatchlist(firstNode.data)
    }
  }, [selectedWatchlist, selectWatchlist])

  // reset selection/focus after deletion
  useEffect(() => {
    gridRef.current?.api?.forEachNodeAfterFilter(
      (node: IRowNode<WatchList>) => {
        if (node.data === selectedWatchlist) {
          node.setSelected(true)
          gridRef.current?.api.setFocusedCell(node.rowIndex || 0, 'name')
        } else {
          node.setSelected(false)
        }
      }
    )
  }, [selectedWatchlist])

  const getRowStyle = useCallback(({ data }: RowClassParams<WatchList>) => {
    if (!data?.canEdit) return { color: 'red' }
  }, [])

  useImperativeHandle(
    handle,
    () => ({
      reset: () => gridRef.current?.api.setFilterModel(null)
    }),
    [gridRef.current]
  )

  return (
    <div className={theme} data-testid="watchlist-manager-grid">
      <AgGridReact<WatchList>
        ref={gridRef}
        rowData={watchlists}
        defaultColDef={defaultColumnDefinitions}
        columnDefs={columns}
        columnMenu="legacy"
        overlayLoadingTemplate="Loading watchlists..."
        overlayNoRowsTemplate="No watchlists found."
        getRowId={getRowId}
        getRowStyle={getRowStyle}
        onRowClicked={onRowClicked}
        onCellFocused={onCellFocused}
        onCellKeyDown={onCellKeydown}
        enableBrowserTooltips={true}
      />
    </div>
  )
}

export default React.memo(forwardRef(WatchlistGrid))
