import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import {
  deselectSecurityOrdersToCancel,
  selectSecurityOrdersToCancel,
  toggleCancelSubOrderSelection
} from '../../../store/listTrading/actions'
import { getOrdersSelectedForCancel } from '../../../store/listTrading/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import {
  getHedgeOrder,
  getListTradeOrders
} from '../../../store/order/selectors'
import { Order } from '../../../store/order/types'
import { isPending } from '../helpers'

export const useListTradingAggressOrders = (
  securityId?: ListTradingSecurity['id'],
  isBid?: boolean | null,
  watchlistId?: number
) => {
  const dispatch = useAppDispatch()
  const getAggressOrders = useAppSelector(getListTradeOrders)
  const getSelectedOrders = useAppSelector(getOrdersSelectedForCancel)
  const getTreasuryHedge = useAppSelector(getHedgeOrder)
  const oppositeType = isBid ? 'buy' : 'sell'

  const toggleOrderCancel = useCallback(
    (orderId: string) => {
      if (securityId) {
        dispatch(toggleCancelSubOrderSelection(securityId, orderId))
      }
    },
    [dispatch]
  )

  const selectOrDeselectAllSecurityOrders = useCallback(
    (select: boolean) => {
      if (!securityId || !watchlistId) return
      if (select) {
        const selectOrders = getAggressOrders(
          securityId,
          oppositeType,
          watchlistId
        )
          .filter(isPending)
          .map((order) => order.id)
        dispatch(selectSecurityOrdersToCancel(securityId, selectOrders))
      } else {
        dispatch(deselectSecurityOrdersToCancel(securityId))
      }
    },
    [dispatch, getAggressOrders, oppositeType]
  )

  if (!watchlistId || !securityId) {
    return {
      hasPendingOrders: false,
      orders: [] as Order[],
      hedgeOrders: [] as Order[],
      selectedOrderIds: [] as string[],
      /* tslint:disable:no-empty */
      toggleOrderCancel: (_orderId: string) => {
        /* intentionally left blank */
      },
      /* tslint:disable:no-empty */
      selectOrDeselectAllSecurityOrders: () => {
        /* intentionally left blank */
      }
    }
  }

  const orders = getAggressOrders(securityId, oppositeType, watchlistId)
  const hedgeOrders = orders
    .map((order) => getTreasuryHedge(order.id))
    .filter((order) => !!order)
  const selectedOrderIds = getSelectedOrders(securityId)
  const hasPendingOrders = orders.some(isPending)

  return {
    hasPendingOrders,
    orders,
    hedgeOrders,
    selectedOrderIds,
    toggleOrderCancel,
    selectOrDeselectAllSecurityOrders
  }
}
