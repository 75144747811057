import { GridApi } from '@ag-grid-community/core'
import { useEffect } from 'react'

const useAgGridLoading = <T = any>(
  rowData: T[] | undefined,
  pending: boolean,
  error: boolean,
  gridApi?: GridApi<T>
) => {
  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('loading', pending)
      if (error || !rowData?.length) {
        gridApi.showNoRowsOverlay()
      }
    }
  }, [pending, error, rowData, gridApi])
}

export default useAgGridLoading
