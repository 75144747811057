import { CustomCellRendererProps } from '@ag-grid-community/react'
import {
  faTrashCan,
  faTrashCanCheck
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import IconCheckbox from '../../../components/IconCheckbox/IconCheckbox'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'

type HideRowCellProps = CustomCellRendererProps<
  SecurityStaticData,
  any,
  TradingListContext
> & { className?: string }
const defaultIcon = () => <FontAwesomeIcon icon={faTrashCan} />
const checkedIcon = () => <FontAwesomeIcon icon={faTrashCanCheck} />

const HideRowCell = ({
  data,
  context: { securitiesToHide, setSecuritiesToHide }
}: HideRowCellProps) => {
  const onChange = useCallback(
    (value: boolean) => {
      if (!data) return
      setSecuritiesToHide((oldVals: number[]) => {
        const removeMe = oldVals.filter((securityId) => securityId !== data.id)
        if (!value) return removeMe
        return [...removeMe, data.id]
      })
    },
    [securitiesToHide]
  )

  if (!data) {
    return <></>
  }
  return (
    <IconCheckbox
      testId={`lt-hide-security-${data.id}`}
      checked={securitiesToHide.includes(data.id)}
      checkedIcon={checkedIcon}
      defaultIcon={defaultIcon}
      onChange={onChange}
    />
  )
}

export default HideRowCell
