import cx from 'classnames'
import React, { FC, useEffect } from 'react'

import { GetRowIdParams } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { defaultColumnDefinitions } from '../../../helpers/formatting'
import { activityLogFetch } from '../../../store/activityLog/actions'
import {
  getActivityLog,
  getActivityLogError
} from '../../../store/activityLog/selectors'
import { Activity } from '../../../store/activityLog/types'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import { getCurrentTheme } from '../../../store/userPreferences/selectors'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import { finHeaderButtons } from '../../ComponentHeader/helpers'
import gridStyles from '../../Grid/grid.module.scss'
import { columnDefinitions } from './columnDefs'

const getActivityLogRowId = ({ data }: GetRowIdParams<Activity>) => {
  return `${data.matId ?? ''}`
}

const ActivityLogGrid: FC = () => {
  const dispatch = useAppDispatch()
  const activityLog = useAppSelector(getActivityLog)
  const activityLogError = useAppSelector(getActivityLogError)
  const theme = useAppSelector(getCurrentTheme)

  useEffect(() => {
    dispatch(activityLogFetch())
  }, [])

  if (activityLogError) {
    return <div>{activityLogError}</div>
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Activity Log"
        headerButtons={finHeaderButtons('ActivityLog')}
      />
      <div className={cx(gridStyles.gridDimensions, theme)}>
        <AgGridReact<Activity>
          defaultColDef={defaultColumnDefinitions}
          rowData={activityLog}
          columnDefs={columnDefinitions}
          columnMenu="legacy"
          overlayNoRowsTemplate="No activity found."
          overlayLoadingTemplate="Loading activity…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowId={getActivityLogRowId}
        />
      </div>
    </div>
  )
}

export default ActivityLogGrid
