import { GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserDashboard } from '../../../../store/admin/userDashboard/selectors'
import { UserDashboardEntry } from '../../../../store/admin/userDashboard/types'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import { rowSelection } from '../../helpers'
import { columnDefinitions } from './columnDefs'

const getRowId: GetRowIdFunc<UserDashboardEntry> = ({ data }) => {
  return `${data?.id ?? ''}`
}

const UserDashboardGrid: FC = () => {
  const userDashboard = useSelector(getUserDashboard)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)

  const onGridReady = useCallback(({ api }: { api: GridApi }) => {
    if (!gridApi) {
      setGridApi({ api })
      const columnState = localStorage.getItem('columnState')
      if (columnState) {
        const parsedState = JSON.parse(columnState)
        api.applyColumnState(parsedState)
      }
    }
  }, [])

  return (
    <AgGridReact
      rowData={userDashboard}
      columnDefs={columnDefinitions}
      columnMenu="legacy"
      overlayNoRowsTemplate="No users connected."
      overlayLoadingTemplate="Loading Active User Dashboard…"
      alwaysShowVerticalScroll={true}
      suppressDragLeaveHidesColumns={true}
      defaultColDef={defaultColumnDefinitions}
      onGridReady={onGridReady}
      rowSelection={rowSelection}
      grandTotalRow="bottom"
      getRowId={getRowId}
    />
  )
}

export default UserDashboardGrid
