import { CustomCellRendererProps } from '@ag-grid-community/react'
import React, { useCallback, useMemo, useState } from 'react'
import Modal from '../../../components/GenericModal/Modal'
import RadioButtonGroup from '../../../components/RadioButtonGroup/RadioButtonGroup'
import toggleStyles from '../../../components/RadioButtonGroup/ToggleStyles.module.scss'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'
import { useWorkingListTradingOrder } from '../hooks/useWorkingListTradingOrder'
import styles from '../grid.module.scss'

const toggleOptions = [
  { label: 'Market', value: 'market' },
  { label: 'Limit', value: 'limit' }
]

const OrderTypeEditor = ({
  data: security,
  context: { watchlistId }
}: CustomCellRendererProps<SecurityStaticData, any, TradingListContext>) => {
  const [showPrompt, setShowPrompt] = useState(false)
  const [tempPrice, setTempPrice] = useState<string>()
  const [tempSpread, setTempSpread] = useState<string>()

  const { order, updateFields } = useWorkingListTradingOrder(
    watchlistId,
    security?.id,
    security?.isin,
    true
  )
  const orderType = useMemo(() => {
    if (typeof order.price === 'number') {
      return 'limit'
    }
    return 'market'
  }, [order.isSpread, order.price])
  const toggleType = useCallback(() => {
    if (orderType === 'market') {
      setShowPrompt(true)
    } else {
      updateFields({
        price: undefined,
        isSpread: undefined
      })
    }
  }, [orderType])
  const onPromptClose = useCallback(() => {
    if (tempPrice || tempSpread) {
      const isSpread = !!tempSpread
      const price = Number(tempPrice || tempSpread)
      updateFields({
        isSpread,
        price
      })
    }
    setShowPrompt(false)
  }, [tempPrice, tempSpread])

  return (
    <div>
      <RadioButtonGroup
        options={toggleOptions}
        className={toggleStyles.toggle}
        onSelect={toggleType}
        selectedOption={orderType}
        testPrefix="lt-toggle-market-limit"
      />
      {showPrompt && (
        <Modal customWrapperStyles={styles.marketLimitModal}>
          <p>Please enter a price or spread.</p>
          <div>
            <label>
              Price:
              <input
                data-testid="lt-set-limit-price"
                type="text"
                value={tempPrice}
                onChange={(e) => {
                  setTempPrice(e.currentTarget.value)
                  setTempSpread(undefined)
                }}
              />
            </label>
            <label>
              Spread:
              <input
                data-testid="set-limit-spread"
                type="text"
                value={tempSpread}
                onChange={(e) => {
                  setTempSpread(e.currentTarget.value)
                  setTempPrice(undefined)
                }}
              />
            </label>
          </div>
          <div>
            <button
              data-testid="submit-limit-type"
              onClick={onPromptClose}
              disabled={!(tempSpread || tempPrice)}
            >
              OK
            </button>
            <button
              data-testid="cancel-limit-type"
              onClick={() => setShowPrompt(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default OrderTypeEditor
