import { GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import useAgGridLoading from '../../../../helpers/hooks/useAgGridLoading'
import {
  getExternalLiquidityStats,
  getExternalLiquidityStatsError,
  getExternalLiquidityStatsPending
} from '../../../../store/admin/externalLiquidityStats/selectors'
import { externalLiquidityColumnModelChanged } from '../../../../store/settings/actions'
import { getExternalLiquidityColumnOrder } from '../../../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import { rowSelection, useAdminStickyColumns } from '../../helpers'
import { columnDefinitions } from './columnDefs'

const ExternalLiquidityStatsGrid: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const pending = useAppSelector(getExternalLiquidityStatsPending)
  const error = useAppSelector(getExternalLiquidityStatsError)
  const externalLiquidityStats = useAppSelector(getExternalLiquidityStats)
  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }
  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)

  useAgGridLoading(externalLiquidityStats, pending, error, gridApi?.api)

  const columnsOrder = useAppSelector(getExternalLiquidityColumnOrder) ?? []

  const onGridReady = useCallback(({ api }: { api: GridApi }) => {
    if (!gridApi) {
      setGridApi({ api })
    }
  }, [])

  const onColumnsChanged = useCallback(
    (displayed: string[]) => {
      if (!dataInitialized) {
        return
      }
      dispatch(externalLiquidityColumnModelChanged(displayed))
    },
    [dataInitialized]
  )

  const { handleColumnChange, columnDefs } = useAdminStickyColumns(
    columnsOrder,
    columnDefinitions,
    onColumnsChanged
  )

  return (
    <div className={cx(gridStyles.gridDimensions, theme)}>
      <AgGridReact
        rowData={externalLiquidityStats}
        columnDefs={columnDefs}
        defaultColDef={defaultColumnDefinitions}
        columnMenu="legacy"
        overlayNoRowsTemplate="No external liquidity data."
        overlayLoadingTemplate="Loading external liquidity data. This may take up to several minutes depending on server load."
        alwaysShowVerticalScroll={true}
        suppressDragLeaveHidesColumns={true}
        onColumnMoved={handleColumnChange}
        onDisplayedColumnsChanged={handleColumnChange}
        onFirstDataRendered={onDataInitialized}
        rowSelection={rowSelection}
        onGridReady={onGridReady}
        grandTotalRow="bottom"
      />
    </div>
  )
}

export default ExternalLiquidityStatsGrid
