import { GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import useAgGridLoading from '../../../../helpers/hooks/useAgGridLoading'
import {
  getExternalOrderStats,
  getExternalOrderStatsError,
  getExternalOrderStatsPending
} from '../../../../store/admin/externalOrderStats/selectors'
import { ExternalOrderStatsTableRow } from '../../../../store/admin/externalOrderStats/types'
import { externalOrderColumnModelChanged } from '../../../../store/settings/actions'
import { getExternalOrderColumnOrder } from '../../../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import gridStyles from '../../../Grid/grid.module.scss'
import { rowSelection, useAdminStickyColumns } from '../../helpers'
import { columnDefinitions } from './columnDefs'

const getRowId: GetRowIdFunc<
  ExternalOrderStatsTableRow & { id: string | number }
> = ({ data }) => {
  return `${data?.id ?? ''}`
}
const ExternalOrderStatsGrid: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const pending = useAppSelector(getExternalOrderStatsPending)
  const error = useAppSelector(getExternalOrderStatsError)
  const externalOrderStats = useAppSelector(getExternalOrderStats)
  const [dataInitialized, setDataInitialized] = useState(false)

  const onDataInitialized = () => {
    setDataInitialized(true)
  }
  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)
  const [externalOrderStatsRowData, setexternalOrderStatsRowData] = useState<
    ExternalOrderStatsTableRow[]
  >([])

  const columnsOrder = useAppSelector(getExternalOrderColumnOrder) ?? []

  useEffect(() => {
    if (externalOrderStats) {
      setexternalOrderStatsRowData(externalOrderStats.rows)
    } else {
      setexternalOrderStatsRowData([])
    }
  }, [externalOrderStats])

  const onGridReady = useCallback(({ api }: { api: GridApi }) => {
    if (!gridApi) {
      setGridApi({ api })
    }
  }, [])

  useAgGridLoading(externalOrderStatsRowData, pending, error, gridApi?.api)

  const onColumnsChanged = useCallback(
    (displayed: string[]) => {
      if (!dataInitialized) {
        return
      }
      dispatch(externalOrderColumnModelChanged(displayed))
    },
    [dataInitialized]
  )
  const { handleColumnChange, columnDefs } = useAdminStickyColumns(
    columnsOrder,
    columnDefinitions,
    onColumnsChanged
  )
  return (
    <div className={cx(gridStyles.gridDimensions, theme)}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={externalOrderStatsRowData}
        defaultColDef={defaultColumnDefinitions}
        columnMenu="legacy"
        overlayNoRowsTemplate="No user data."
        overlayLoadingTemplate="Loading API user data. This may take up to several minutes depending on server load."
        suppressDragLeaveHidesColumns={true}
        onColumnMoved={handleColumnChange}
        onDisplayedColumnsChanged={handleColumnChange}
        onFirstDataRendered={onDataInitialized}
        alwaysShowVerticalScroll={true}
        onGridReady={onGridReady}
        enableBrowserTooltips={true}
        rowSelection={rowSelection}
        grandTotalRow="bottom"
        getRowId={getRowId}
      />
    </div>
  )
}

export default ExternalOrderStatsGrid
