import { GridApi } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { FC, useCallback, useState } from 'react'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'
import {
  getActivityTicker,
  getActivityTickerError
} from '../../../../store/admin/activityTicker/selectors'
import { useAppSelector } from '../../../../store/types'
import { columnDefinitions } from './columnDefs'

const ActivityTickerGrid: FC = () => {
  const activityTicker = useAppSelector(getActivityTicker)
  const activityTickerError = useAppSelector(getActivityTickerError)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
  } | null>(null)

  const onGridReady = useCallback(({ api }: { api: GridApi }) => {
    if (!gridApi) {
      setGridApi({ api })
      const sortModel = [{ colId: 'orderTime', sort: 'desc' } as const]
      api.applyColumnState({ state: sortModel })
    }
  }, [])

  if (activityTickerError) {
    return <div>{activityTickerError}</div>
  }

  return (
    <AgGridReact
      rowData={activityTicker}
      columnDefs={columnDefinitions}
      columnMenu="legacy"
      overlayNoRowsTemplate="No activity found."
      overlayLoadingTemplate="Loading Activity Ticker…"
      alwaysShowVerticalScroll={true}
      defaultColDef={defaultColumnDefinitions}
      onGridReady={onGridReady}
      enableBrowserTooltips={true}
    />
  )
}

export default ActivityTickerGrid
